export const autoTargetBlankEnhancer = function (el) {
    let internal = window.location.host.replace('www.', '');
    internal = new RegExp(internal, 'i');

    const anchorElements = el.getElementsByTagName('a');
    Array.from(anchorElements, function (a, i) {
        const href = a.host;
        if (!internal.test(href)) {
            a.setAttribute('target', '_blank');
        }
    });
};
