import { isVisible, slideDown, slideUp } from 'slide-anim';

export const slideNextHandler = (el) => {
    const item = el.parentNode.nextElementSibling;
    if (isVisible(item)) {
        slideUp(item);
        el.setAttribute('aria-expanded', false);
    } else {
        slideDown(item);
        el.setAttribute('aria-expanded', true);
    }
};
