const COUNTRY_URL = '/country';

const getMeta = (metaName) => {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i += 1) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }

    return '';
};

const json = (response) => {
    return response.json();
};

export const autoSelectCountryEnhancer = (el) => {
    fetch(COUNTRY_URL, {
        method: 'post',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': getMeta('csrf-token'),
        },
    })
        .then(json)
        .then((response) => {
            if (typeof response.id !== 'undefined' && response.id !== null) {
                const countryId = response.id;
                el.value = countryId;
            }
        })
        .catch((error) => {});
};
