import { slideDown } from 'slide-anim';
import { formatValue } from './format-value';
import { currentLocale } from './i18n';

let calculatedTotalBonds = 0;
let bondsNominalValue;

const dispatchChange = function (el) {
    const event = new Event('change');
    el.dispatchEvent(event);
};

const stepUp = function (el) {
    const currentValue = parseInt(el.value, 10);
    if (currentValue + 1 <= parseInt(el.getAttribute('max'), 10)) {
        el.value = currentValue + 1;
        dispatchChange(el);
    }
};

const stepDown = function (el) {
    const currentValue = parseInt(el.value, 10);
    if (currentValue - 1 >= parseInt(el.getAttribute('min'), 10)) {
        el.value = currentValue - 1;
        dispatchChange(el);
    }
};

const calculateTotalBonds = function (setFields, getFields) {
    calculatedTotalBonds = 0;
    for (let i = 0; i < getFields.length; i += 1) {
        calculatedTotalBonds += parseInt(getFields[i].value, 10);
    }
    for (let j = 0; j < setFields.length; j += 1) {
        setFields[j].innerText = calculatedTotalBonds;
    }
    document.querySelector(
        '.tranche--action-extend-overview .subscribe-as-guest__value'
    ).innerHTML = formatValue(
        calculatedTotalBonds * bondsNominalValue,
        currentLocale()
    );
};

export const numberFieldManipulatorHandler = function (el) {
    const fieldName = el.getAttribute('data-field');
    const field = document.getElementById(fieldName);
    const executeFunction = el.getAttribute('data-function');
    switch (executeFunction) {
        case 'stepUp':
            stepUp(field);
            break;
        case 'stepDown':
            stepDown(field);
            break;
        default:
            break;
    }
};

const keepInBoundsEnhancer = function (el) {
    const min = parseInt(el.getAttribute('min'), 10);
    const max = parseInt(el.getAttribute('max'), 10);

    if (el.value.trim() === '') {
        el.value = min;
    } else if (Number.isNaN(el.value)) {
        el.value = max;
    } else if (el.value > max) {
        el.value = max;
    } else if (el.value < min) {
        el.value = min;
    }
};

export const calculateTotalEnhancer = function (el) {
    const setSelector = el.getAttribute('data-set-selector');
    const getSelector = el.getAttribute('data-get-selector');
    const setFields = document.querySelectorAll(setSelector);
    const getFields = document.querySelectorAll(getSelector);

    bondsNominalValue =
        parseInt(el.getAttribute('data-nominal-value'), 10) / 100;

    el.addEventListener('change', function () {
        keepInBoundsEnhancer(el);
        calculateTotalBonds(setFields, getFields);
    });
    setTimeout(function () {
        keepInBoundsEnhancer(el);
        calculateTotalBonds(setFields, getFields);
    }, 0);
};

export const showGetNewCodeEnhancer = function (el) {
    const timeout = parseInt(el.getAttribute('data-timeout'), 10);
    setTimeout(function () {
        slideDown(el);
    }, timeout * 1000);
};
